<template>
  <div>
    <div class="container-fluid" id="nav-container">
      <nav-bar></nav-bar>
    </div>
    <SubscriptionModal content-id="5"/>
    <div class="container-fluid">
      <div class="row">
        <HomeSection v-if="!mobile" id="sec1" class="hSec padding" :imgLeft="false" :infoSec="locale[lang].SAISON_SENIOR.INFOSEC1"
          :haveButton="true" :modal="true" :link="locale[lang].MODAL_LINKS.SAISONS_S" />
        <MobileSec id="mobile1" v-else :infoSec="locale[lang].SAISON_SENIOR.INFOSEC1" :titleTop="false" :haveButton="true" :modal="true" :link="locale[lang].MODAL_LINKS.SAISONS_S" />
      </div>
      <div class="row padding justify-content-center" id="sec2">
        <div class="title" data-aos="fade-right">
          <h1 class="mb-3">{{ locale[lang].SAISON_SENIOR.ROTATE.TITLE }}</h1>
        </div>
        <h5 class="mt-4">
          {{ locale[lang].SAISON_SENIOR.ROTATE.SUBTITLE }}
        </h5>
        <RotateSec id="secRot" :iconInfo="locale[lang].SAISON_SENIOR.ROTATE.ICONINFO" :mobile="mobile" />
      </div>
      <div class="row">
        <HomeSection v-if="!mobile" id="sec3" class="hSec padding" :imgLeft="true" :infoSec="locale[lang].SAISON_SENIOR.INFOSEC2"
          :haveButton="false" />
        <MobileSec id="mobile2" v-else :infoSec="locale[lang].SAISON_SENIOR.INFOSEC2" :titleTop="true" :haveButton="false" />
      </div>

      <!-- Session cards  -->

      <div class="row" id="sessions">
        <div class="col-12">
          <div class="row justify-content-center" v-if="!mobile">
            <div class="col-12">
              <h5 v-if="!mobile">
                {{ locale[lang].SAISON_SENIOR.CARDS.TOP }}
              </h5>
            </div>
          </div>
          <div class="row justify-content-center mb-5" data-aos="fade-right">
            <div class="col-12 title">
              <h2 v-if="!mobile">
                {{ locale[lang].SAISON_SENIOR.CARDS.TITLE }}
              </h2>
              <h2 v-else>{{ locale[lang].SAISON_SENIOR.CARDS.TITLE_MOBILE }}</h2>
            </div>
          </div>
          <div class="row" id="cards1" v-if="!mobile">
            <div class="col-6" v-for="(card, key) in locale[lang].SAISON_SENIOR.CARDS.CARDS1" :key="key">
              <SessionCard :card="card" />
            </div>
          </div>
          <div class="row" id="cards2" v-if="!mobile">
            <div class="col-6" v-for="(card, key) in locale[lang].SAISON_SENIOR.CARDS.CARDS2" :key="key">
              <SessionCard :card="card" />
            </div>
          </div>
          <div class="row" id="carouselCards" v-else>
            <CarouselCards :cardsLeft="locale[lang].SAISON_SENIOR.CARDS.CARDS1" :cardsRight="locale[lang].SAISON_SENIOR.CARDS.CARDS2" :jeunes="false" />
          </div>
          <div class="row">
            <div class="col-12 text-align-center">
              <button class="btn" @click="openModal(locale[lang].MODAL_LINKS.SAISONS_S)">
                {{ locale[lang].SAISON_SENIOR.CARDS.BUTTON }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row" id="satisfaction">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12" id="garantie">
          <img class="img-fluid" :src="require('@/assets/Images/Practice/' + locale[lang].GARANTIE_IMG)" alt="garantie" />
          <h2> {{ locale[lang].SAISON_SENIOR.GARANTIE.TITLE }} </h2>
          <p>
            {{ locale[lang].SAISON_SENIOR.GARANTIE.SUBTITLE }}
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12" id="paiement">
          <img class="img-fluid" :src="require('@/assets/Images/Practice/' + locale[lang].PAY_IMG)" alt="paiement" />
          <h2> {{ locale[lang].SAISON_SENIOR.PAIEMENT.TITLE }} </h2>
          <p>
            {{ locale[lang].SAISON_SENIOR.PAIEMENT.SUBTITLE }}
          </p>
        </div>
        <div class="col-12" id="page">
          <h3>
            {{ locale[lang].SAISON_SENIOR.CONTACT.TEXT_1 }}
            <br />
            {{ locale[lang].SAISON_SENIOR.CONTACT.TEXT_2 }}
            <a href="javascript:;" @click="$router.push({ name: 'Contacto' })">{{ locale[lang].SAISON_SENIOR.CONTACT.LINK }}</a>
          </h3>
        </div>
      </div>

      <div class="row" id="exemples">
        <div class="col-12">
          <div class="row examples justify-content-center">
            <div class="title" data-aos="fade-right">
              <h1>{{ locale[lang].SAISON_SENIOR.EXERCICES.TITLE }}</h1>
            </div>
            <p v-if="!mobile">
              {{ locale[lang].SAISON_SENIOR.EXERCICES.SUBTITLE }}
            </p>
          </div>
          <div class="row" v-if="!mobile">
            <div class="col-lg-4 col-md-4 col-sm-12 col-12" v-for="card in locale[lang].SAISON_SENIOR.EXERCICES.CARDS" :key="card.id"
              :id="'card-' + card.id">
              <CollapseCard :class="{ open: card.open }" :id="card.id" :cardInfo="card" @open="open" />
            </div>
          </div>
          <div class="row px-3" v-else>
            <ExerciceCardsM :infoCards="locale[lang].SAISON_SENIOR.EXERCICES.CARDS" :collapse="true" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12" id="check">
          <div class="img-check">
            <img src="@/assets/Images/Saison/check-circle.png" alt="check-img" />
          </div>
          <div class="text-check">
            <h1>{{ locale[lang].SAISON_SENIOR.CHECK.TITLE }}</h1>
            <h5 v-if="!mobile" v-html="locale[lang].SAISON_SENIOR.CHECK.CONTENT">
            </h5>
            <div v-else>
              <h5 v-for="(check, key) in locale[lang].SAISON_SENIOR.CHECK.CONTENT_MOBILE" :key="key">
                <i class="fas fa-check-circle"></i> {{ check }}
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <OpinionCarousel id="carousel" :title="locale[lang].SAISON_SENIOR.OPINION_TITLE" :mobile="mobile" />
      </div>
      <div class="row padding" id="end">
        <div class="col-12" id="card-end">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 col-sm-12 col-12" data-aos="fade-right">
              <h1>{{ locale[lang].SAISON_SENIOR.END.TEXT }}</h1>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 col-12" data-aos="fade-left">
              <button class="btn" @click="openModal(locale[lang].MODAL_LINKS.SAISONS_S)">{{ locale[lang].SAISON_SENIOR.END.BUTTON }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" id="footer-container" >
      <Footer />
    </div>
  </div>
</template>

<script>
  import HomeSection from "@/components/Home/HomeSection.vue";
  import RotateSec from "@/components/Home/RotateSec.vue";
  import OpinionCarousel from "@/components/OpinionCarousel.vue";
  import SessionCard from "@/components/SessionCard.vue";
  import CollapseCard from "@/components/CollapseCard.vue";
  import MobileSec from "@/components/MobileSec.vue";
  import CarouselCards from "@/components/Saison/CarouselCards.vue";
  import ExerciceCardsM from "@/components/ExerciceCardsM.vue";
  import NavBar from "@/components/essential/NavBar.vue";
  import Footer from "@/components/essential/Footer.vue";
  import defaultMixin from "@/mixins/defaultMixin";
  import modalMixin from "@/mixins/modalMixin";

  export default {
    mixins: [defaultMixin, modalMixin],
    components: {
      HomeSection,
      RotateSec,
      OpinionCarousel,
      CarouselCards,
      SessionCard,
      CollapseCard,
      ExerciceCardsM,
      MobileSec,
      NavBar,
      Footer,
    },
    methods: {
      open(id) {
        for (const card of this.locale[this.lang].SAISON_SENIOR.EXERCICES.CARDS) {
          if (id === card.id) {
            if (card.open) {
              card.open = false;
            } else {
              card.open = true;
            }
          } else {
            card.open = false;
          }
        }
      },
    },
    data() {
      return {
        mobile: false,
      };
    },
  };
</script>

<style scoped>
  .padding {
    padding: 0 150px;
  }

  #sec2,
  .examples {
    position: relative;
  }

  #exemples {
    padding: 0 100px 5% 100px;
  }

  .title::after {
    content: "";
    position: absolute;
    border-bottom: 3px solid #ff0313;
    width: 60px;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 1;
    bottom: -12px;
  }

  .fa-check-circle {
    color: #ff0313;
  }

  .examples .title::after {
    bottom: 45px;
  }

  .examples .title h1 {
    text-align: center;
    font: normal normal 800 38px/47px Montserrat;
    letter-spacing: 0px;
    color: #0f1010;
    opacity: 1;
    margin-bottom: 75px;
  }

  .examples p {
    text-align: center;
    font: normal normal 500 22px/35px Montserrat;
    letter-spacing: 0.44px;
    color: #242424;
    opacity: 1;
    margin-bottom: 30px;
  }

  #card-1>>>.card-hide {
    margin-top: 17.06% !important;
  }

  #card-2>>>.card-hide {
    margin-top: 19.47% !important;
  }

  #card-1>>>.open .card-hide {
    margin-top: 0 !important;
  }

  #card-2>>>.open .card-hide {
    margin-top: 0 !important;
  }

  #sec3>>>.title-sec {
    position: relative;
  }

  #sec3>>>.title-sec::after {
    content: "";
    position: absolute;
    border-bottom: 3px solid #ff0313;
    width: 60px;
    left: 0;
    opacity: 1;
    bottom: -20px;
  }

  .hSec>>>.title-sec h2 {
    text-align: left;
    font: normal normal 800 38px/47px Montserrat;
    letter-spacing: 0px;
    color: #0f1010;
    opacity: 1;
  }

  #sec1>>>.text-sec p {
    line-height: 30px !important;
    letter-spacing: 0 !important;
  }

  .hSec>>>.text-sec p {
    text-align: left;
    font: normal normal 500 22px/24px Montserrat;
    letter-spacing: 0.44px;
    color: #242424;
    opacity: 1;
  }

  .hSec>>>.btn {
    font: normal normal bold 22px/27px Montserrat;
    border-radius: 15px;
    padding: 24px 120px;
  }

  .hSec>>>.button-sec {
    text-align: left;
  }

  #sec2 h1 {
    text-align: center;
    font: normal normal 800 38px/47px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin-bottom: 62px;
  }

  .title {
    position: relative;
  }

  #sec2 h5 {
    text-align: center;
    font: normal normal 500 24px/32px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin-bottom: 55px;
  }

  #secRot>>>.container-fluid {
    padding: 0;
  }

  #secRot>>>.text p {
    font-size: 17px;
  }

  #secRot>>>.rotate {
    margin-left: 35%;
  }

  #secRot>>>#rot-0 img {
    top: 16%;
    left: 44.5%;
  }

  #secRot>>>#rot-1 img {
    top: 15%;
    left: 43.5%;
  }

  #secRot>>>#rot-2 img {
    top: 17.5%;
    left: 44%;
  }

  #secRot>>>#rot-3 img {
    top: 15%;
    left: 46%;
  }

  #cards2,
  #cards1 {
    padding: 0 23%;
  }

  #cards1 >>> .img-fluid {
    border-radius: 30px 30px 0 0;
  }

  #sec3>>>.text {
    padding: 0;
  }

  #sec3>>>.text-sec p {
    line-height: 35px !important;
  }

  #satisfaction {
    background-image: url("../assets/Images/Practice/Enmascarar grupo 149.png"),
      url("../assets/Images/Cycles/Enmascarar grupo 149.png"),
       -webkit-linear-gradient(top, #414141, #414141 70%, transparent 70%, transparent 100%);
    background-size: auto;
    background-repeat: no-repeat;
    background-position: 0 0, 50% 100%;
    padding: 14% 0%;
  }

  #satisfaction #garantie {
    padding-left: 12%;
    padding-right: 6%;
    position: relative;
  }

  #satisfaction #garantie::after {
    content: "";
    position: absolute;
    height: 75%;
    left: 99%;
    top: 20%;
    border-right: 1px solid rgba(255, 255, 255, 0.7);
  }

  #satisfaction #paiement {
    padding: 7% 8% 0 5%;
  }

  #satisfaction h2 {
    text-align: center;
    font: normal normal 800 30px/37px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
    margin: 27px 0;
  }

  #satisfaction p {
    font: normal normal 500 22px/30px Montserrat;
    letter-spacing: 0.44px;
    text-align: center;
    color: #ffffff !important;
  }

  #page {
    padding: 8% 10% 0 10%;
  }

  #page h3 {
    text-align: center;
    font: normal normal 600 35px/50px Montserrat;
    letter-spacing: 0px;
    opacity: 1;
    color: #ffffff;
  }

  #page h3 a {
    color: #f9334a;
  }

  #stf,
  #check {
    display: inline-flex;
    align-items: center;
  }

  #stf {
    margin-top: 19%;
  }

  #stf .img-s {
    margin-left: 10%;
    margin-right: 30px;
  }

  #stf .text-s {
    padding-right: 10%;
  }

  #stf p {
    text-align: left;
    font: normal normal 500 20px/35px Montserrat;
    letter-spacing: 0.4px;
    color: #ffffff;
    opacity: 1;
  }

  #check .img-check {
    margin-left: 8%;
    margin-right: 20px;
  }

  #check .text-check {
    margin-top: 4%;
  }

  #check .text-check h1 {
    text-align: left;
    font: normal normal 800 38px/47px Montserrat;
    letter-spacing: 0px;
    color: #0f1010;
    opacity: 1;
  }

  #check .text-check h5 {
    text-align: left;
    font: normal normal 600 28px/34px Montserrat;
    letter-spacing: 0px;
    color: #0f1010;
    opacity: 1;
  }

  #end h1 {
    text-align: left;
    font: normal normal 800 38px/47px Montserrat;
    letter-spacing: 0px;
    color: #0f1010;
    opacity: 1;
  }

  .btn {
    background-image: url("../assets/Images/Background/ball.png");
    background-repeat: no-repeat;
    background-position: 100% 50%;
    /* border: 3px solid #0f1010 !important; */
    box-shadow: 0px 3px 30px #00000029;
    color: #0f1010;
    border-radius: 15px;
    font: normal normal bold 26px/32px Montserrat;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
    background-size: 95px 80px;
    height: 85px;
  }

  .btn:hover {
    background-color: rgba(209, 209, 209, 0.507);
    margin-left: 20px;
  }

  #sessions .btn {
    font-size: 22px;
    line-height: 27px;
    padding: 24px 47px;
    background-color: #0f1010;
    box-shadow: 0px 3px 30px #f1f1f129;
    color: #ffffff;
    margin-top: 10%;
  }

  #sessions .btn:hover {
    background-color: rgba(63, 62, 62, 0.707);
    margin-left: 12px;
  }

  #sessions h2 {
    text-align: center;
    font: normal normal 800 38px/47px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin-top: 12%;
  }

  #sessions h5 {
    text-align: center;
    font: normal normal 600 28px/34px Montserrat;
    letter-spacing: 0px;
    color: #0f1010;
    opacity: 1;
    margin: 0 16.5%;
  }

  #end .btn {
    height: auto;
    padding: 28px 100px;
  }

  #sessions {
    background-image: url("../assets/Images/Saison/Trazado 1897.png"),
    url("../assets/Images/Background/Trazado 1903.png"),
      url("../assets/Images/Saison/Grafismo 2.png"),
      -webkit-linear-gradient(bottom, #414141, #414141 70%, transparent 70%, transparent 100%);
    background-repeat: no-repeat, no-repeat;
    background-position: 94% 100%, 0% 100%, 0 0;
    background-size: auto, auto, 100% 105%;
    padding: 10% 0 0 0;
  }

  #sec2 {
    background-image: url("../assets/Images/Formation/Enmascarar grupo 133.png");
    background-repeat: no-repeat;
    background-position: 100% 60%;
    background-size: auto;
    padding: 19% 11%;
  }

  #check {
    background-image: url("../assets/Images/Saison/Enmascarar grupo 130.png");
    background-repeat: no-repeat;
    background-position: 100% 60%;
    background-size: cover;
    padding: 8% 0;
  }

  #carousel {
    background-image: url("../assets/Images/Saison/Grafismo1.png"),
      url("../assets/Images/Formation/Grupo 1109.png");
    background-repeat: no-repeat, no-repeat;
    background-position: 0 30%, 50% 80%;
    padding: 5% 5% 25% 5% !important;
  }

  #end {
    background-image: url("../assets/Images/Saison/Grupo 691.png");
    background-repeat: no-repeat;
    background-position: 0 0;
    padding: 0 8% 5% 8%;
  }

  #card-end {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 30px #00000014;
    border-radius: 30px;
    opacity: 1;
    padding: 35px 70px;
    margin-bottom: 200px;
  }

  @media (max-width: 1600px) {

    #page {
      padding-top: 12%;
    }

    #sessions h2 {
      margin-top: 15%;
    }

    .padding {
      padding: 0 100px;
    }

    #stf p {
      font-size: 16px;
      line-height: 30px;
    }

    #sec2 {
      background-position: 0 0;
      padding: 30% 5%;
    }

    #sec2 .title::after {
      bottom: 0;
    }

    #exemples {
      padding: 5% 50px;
    }

    .hSec>>>.btn {
      padding: 24px 50px;
    }

    #secRot>>>.text p {
      font-size: 15px;
    }

    #secRot>>>.rotate {
      margin-left: 32%;
    }

    #secRot>>>#rot-0 img {
      top: 15%;
      left: 44%;
    }

    #secRot>>>#rot-2 img {
      top: 15.5%;
    }

    #secRot>>>#rot-3 img {
      top: 15%;
      left: 46%;
    }

    #carousel {
      background-size: 40%, auto;
    }

    #card-end {
      padding: 20px 50px;
      margin-bottom: 50px;
    }

    #end .btn {
      padding: 28px 70px;
    }
  }

  @media (max-width: 1280px) {
    #sec3 {
      padding: 0 25px;
    }

    #secRot>>>#rot-0 img {
      top: 15%;
      left: 44%;
    }

    #secRot>>>#rot-2 img {
      top: 15.5%;
    }

    #secRot>>>#rot-3 img {
      top: 15%;
      left: 46%;
    }

    #end .btn {
      padding: 28px 50px;
    }
  }

  @media (max-width: 1024px) {
    h1 {
      text-align: center !important;
      font: normal normal 800 20px/24px Montserrat !important;
    }

    .col-lg-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    #mobile1 {
      margin-bottom: 45px;
    }

    #mobile1>>>.title-sec {
      position: relative;
      margin-bottom: 40px;
    }

    #mobile1>>>.title-sec::after{
            content: "";
      position: absolute;
      border-bottom: 3px solid #ff0313;
      width: 60px;
      left: 41%;
      opacity: 1;
      bottom: -20px;
    }

    #mobile1>>>.text-sec p {
      line-height: 24px;
      letter-spacing: 0.26px;
      color: #242424;
      margin-top: 25px;
      margin-bottom: 35px;
      font-size: 16px;
    }

    #mobile1>>>.btn-sc {
      font-size: 16px;
    }

    #sec2 {
      background-image: url("../assets/Images/Saison/Enmascarar grupo 211.png");
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100% 100%;
      padding: 25% 5%;
    }

    #sec2 h1 {
      margin-bottom: 30px;
    }

    #sec2 h5 {
      font: normal normal 500 16px/24px Montserrat;
      letter-spacing: 0.26px;
      margin-bottom: 30px;
    }

    #secRot>>>.text {
      margin-top: 17px;
    }

    #secRot>>>#rot-0,
    #secRot>>>#rot-1 {
      margin-bottom: 30px;
    }

    #secRot>>>.rotate {
      height: 65px;
      width: 65px;
      margin-left: 50px;
    }

    #secRot>>>.text p {
      font-size: 12px;
      line-height: 14px;
      padding: 0;
    }

    #secRot>>>#rot-0 .img-fluid {
      top: 20px !important;
      left: 85px !important;
      width: 26px;
    }

    #secRot>>>#rot-1 .img-fluid {
      top: 19px !important;
      left: 83px !important;
      width: 29px;
    }

    #secRot>>>#rot-2 .img-fluid {
      top: 22px !important;
      left: 84px !important;
      width: 28px;
    }

    #secRot>>>#rot-3 .img-fluid {
      top: 18px !important;
      left: 87px !important;
      width: 21px;
    }

    #mobile2 {
      margin-top: 45px;
    }

    #mobile2>>>img {
      margin-top: 39px;
      margin-bottom: 55px;
    }

    #mobile2>>>.text-sec p {
      text-align: left;
      line-height: 24px;
      letter-spacing: 0.26px;
      color: #242424;
      font-size: 16px;
    }

    #sessions {
      background-image: url("../assets/Images/Saison/Enmascarar grupo 212.png");
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100% 115%;
      padding: 25% 0%;
    }

    #sessions h2 {
      margin-top: 0;
      font: normal normal 800 20px/24px Montserrat;
    }

    #sessions .btn {
      margin-top: 150px;
      padding: 12px 50px;
      font: normal normal bold 18px/18px Montserrat;
    }

    #satisfaction {
      background-image: url("../assets/Images/Cycles/Satisfaction Garantie.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: 0 0;
      padding: 30% 0% 20%;
    }

    #satisfaction #garantie {
      padding: 0 6%;
      margin-bottom: 50px;
    }

    #satisfaction #garantie::after {
      border-right: 0;
    }

    #paiement img {
      max-width: 50%;
    }

    #garantie h2 {
      font-size: 18px;
      line-height: 22px;
    }

    #satisfaction p {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.12px;
      margin-bottom: 5px;
    }

    #paiement h2 {
      font-size: 18px;
      line-height: 26px;
    }

    #page {
      padding: 38% 9% 8%;
    }

    #page h3 {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.12px;
    }

    #page h3 br {
      display: none;
    }

    #carouselCards {
      padding: 0 10%;
    }

    #stf {
      margin-top: 32%;
      display: block;
    }

    #stf .text-s {
      margin-top: 29px;
      padding-right: 0;
    }

    #stf p {
      text-align: center;
      font: normal normal 600 16px/19px Montserrat;
    }

    #exemples {
      padding: 10% 10px;
    }

    #exemples>>>.card-title {
      font-size: 18px;
      line-height: 20px;
    }

    #exemples>>>.card-sub,
    #exemples>>>.card-text p {
      font-size: 16px;
    }

    .examples .title::after {
      bottom: 42%;
    }

    #check {
      background-image: url("../assets/Images/Saison/Enmascarar grupo 213.png");
      background-repeat: no-repeat;
      background-position: 0 0;
      padding: 22% 8% 18% 8%;
      background-size: 100% 100%;
    }

    #check .img-check {
      display: none;
    }

    #check .text-check h1 {
      margin-bottom: 45px;
      position: relative;
    }

    #check .text-check h1::after {
      content: "";
      position: absolute;
      border-bottom: 3px solid #ff0313;
      width: 60px;
      left: 41%;
      opacity: 1;
      bottom: -20px;
    }

    #check .text-check h5 {
      font: normal normal 500 16px/18px Montserrat;
    }

    #carousel {
      background-image: none;
    }

    #carousel {
      padding: 5% 0 25% 0 !important;
    }

    #carousel>>>.col-12 {
      padding: 0 5px;
    }

    #carousel>>>.title {
      padding: 0 10% !important;
      margin-top: 20px;
      margin-bottom: 30px;
      position: relative;
    }

    #carousel>>>.title::after {
      content: "";
      position: absolute;
      border-bottom: 3px solid #ff0313;
      width: 30px;
      left: 47%;
      bottom: -30%;
      opacity: 1;
    }

    #carousel>>>.title h3,
    #end h1 {
      text-align: center;
      font-size: 21px !important;
      line-height: 25px;
    }

    #carousel>>>.opinion-card {
      padding: 15px 16px;
    }

    #carousel>>>.carousel-indicators {
      left: 0;
      bottom: -20%;
    }

    #cardsM>>>.carousel-indicators li,
    #carousel>>>.carousel-indicators li,
    #notiCarousel>>>.carousel-indicators li {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      opacity: 1;
    }

    #carousel>>>.left-img {
      display: flex;
    }

    #carousel>>>.img-cm {
      width: 46px;
      height: 46px;
      margin-right: 14px;
    }

    #carousel>>>p {
      text-align: left;
      font: italic normal 500 12px/21px Montserrat;
      letter-spacing: 0px;
      color: #444444;
    }

    #carousel>>>.body h3 {
      font-size: 13px;
      line-height: 22px;
      margin-bottom: 0;
    }

    #carousel>>>.body h5 {
      font-size: 12px;
      line-height: 22px;
    }

    #end {
      background-size: 20%;
      padding: 0 10%;
      margin-bottom: 80px;
    }

    #end .btn {
      margin-top: 35px;
      font: normal normal bold 14px/15px Montserrat;
      padding: 14px 50px;
      text-transform: uppercase;
    }

    #card-end {
      padding: 35px 20px;
      margin-bottom: 70px;
    }
  }

  @media (max-width: 425px) {
    #carouselCards {
      padding: 0;
    }
  }

  @media (max-width: 375px) {
    #check {
      padding: 20% 8%;
    }

    #secRot>>>.rotate {
      margin-left: 40px;
    }

    #secRot>>>#rot-0 .img-fluid {
      left: 75px !important;
    }

    #secRot>>>#rot-1 .img-fluid,
    #secRot>>>#rot-2 .img-fluid {
      left: 74px !important;
    }

    #secRot>>>#rot-3 .img-fluid {
      left: 77px !important;
    }
  }
</style>