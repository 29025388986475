<template>
  <div class="container-fluid">
    <div class="row mx-auto my-auto" id="carouselCards">
      <div class="col-12">
        <!--Carousel Wrapper-->
        <div
          id="multi-item-cards"
          class="carousel slide carousel-multi-item"
          data-ride="carouselCards"
        >
          <!--Indicators-->
          <ol class="carousel-indicators">
            <li
              data-target="#multi-item-cards"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#multi-item-cards" data-slide-to="1"></li>
            <li data-target="#multi-item-cards" data-slide-to="2"></li>
            <li data-target="#multi-item-cards" data-slide-to="3"></li>
            <li
              data-target="#multi-item-cards"
              data-slide-to="4"
              v-if="jeunes"
            ></li>
          </ol>
          <!--/.Indicators-->

          <!--Slides-->
          <div class="carousel-inner" role="listbox">
            <!--First slide-->
            <div class="carousel-item active">
              <div class="row">
                <div class="col-12">
                  <SessionCard class="cardS" :card="cardsLeft[0]" />
                </div>
              </div>
            </div>
            <!--/.First slide-->

            <!--Second slide-->
            <div class="carousel-item">
              <div class="row">
                <div class="col-12">
                  <SessionCard class="cardS" :card="cardsLeft[1]" />
                </div>
              </div>
            </div>
            <!--/.Second slide-->

            <div class="carousel-item" v-if="jeunes">
              <div class="row">
                <div class="col-12">
                  <SessionCard class="cardS" :card="cardsLeft[2]" />
                </div>
              </div>
            </div>

            <div class="carousel-item">
              <div class="row">
                <div class="col-12">
                  <SessionCard class="cardS" :card="cardsRight[0]" />
                </div>
              </div>
            </div>

            <div class="carousel-item">
              <div class="row">
                <div class="col-12">
                  <SessionCard class="cardS" :card="cardsRight[1]" />
                </div>
              </div>
            </div>
            <!-- Third slide -->
          </div>
          <!--/.Slides-->
        </div>
        <!--/.Carousel Wrapper-->
      </div>
    </div>
  </div>
</template>

<script>
import SessionCard from "@/components/SessionCard.vue";
export default {
  components: {
    SessionCard,
  },
  props: {
    cardsLeft: Array,
    cardsRight: Array,
    jeunes: Boolean,
  },
};
</script>

<style scoped>
.carousel-indicators li {
  background-color: transparent !important;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  opacity: 1;
}

.carousel-indicators .active {
  background-color: #ff0313 !important;
}

.carousel-indicators {
  bottom: -30px;
}

.cardS {
  margin: 0 15px;
}

.cardS >>> .category-card h5 {
  font: normal normal 800 18px/22px Montserrat;
}

.cardS >>> .body-card p {
  font: normal normal 500 14px/18px Montserrat;
  letter-spacing: 0.4px;
}
</style>
