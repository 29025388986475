<template>
  <div class="session-card">
    <div class="img-card">
      <img class="img-fluid" :src="require('@/assets/Images/Saison/' + card.img)" alt="session-img" />
    </div>
    <div class="category-card">
      <h5>{{ card.category }}</h5>
    </div>
    <div class="body-card">
      <div class="session">
        <p>{{ card.body.sessions }}</p>
      </div>
      <div class="interval">
        <p>{{ card.body.interval }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    card: Object,
  },
};
</script>

<style scoped>
.session-card {
  background: #343434 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000026;
  border-radius: 30px;
  opacity: 1;
}

.category-card {
  background: #ff0313 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 10px 0;
}

.category-card h5 {
  text-align: center;
  font: normal normal 800 32px/39px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  text-shadow: 0px 3px 20px #ffffff30;
  opacity: 1;
  margin-bottom: 0;
}

.body-card p {
  text-align: center;
  font: normal normal 500 22px/27px Montserrat;
  letter-spacing: 0.88px;
  color: #dddddd;
  opacity: 1;
}

.interval {
  position: relative;
  margin-bottom: 40px;
}

.interval::after {
  content: "";
  position: absolute;
  border-bottom: 2px solid #707070;
  width: 85%;
  left: 7%;
  top: 0;
  opacity: 1;
}


.body-card .session p {
  padding-top: 10px;
}

.body-card .interval p {
  letter-spacing: 1.76px;
  padding: 15px 35px;
}
</style>
